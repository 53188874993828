<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Terms & <span class="text-green">Conditions</span></h1>
            </div>
        </div>
    </div>
</div>
<div class="container my-5">
    <div class="row">
        <div class="col-md-12">
            <em class="text-muted"><span>Last Updated: May 2018</span></em><br><br>
        </div>
        <div class="col-md-12">
            <h2 class="mb-3">Welcome to Taaj Services</h2>
            <p>Please read these Terms and Conditions (“Terms”, “Terms and Coditions”) carefully before using <a
                    href="https://www.taajservices.com/">https://www.TaajServices.com</a> (the “Service”) operated by
                Taaj International Services (“Taaj Services”, “us”, “we”, or “our”).</p>
            <p>The following terminology applies in these Terms and Conditions below, “Client”, “You” and “Your” refers
                to you, the person accessing this website and accepting the Company’s terms and conditions. “The
                Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers
                to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer,
                acceptance and consideration of payment necessary to undertake the process of our assistance to the
                Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other
                means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s
                stated services/products, in accordance with and subject to, prevailing law of Djibouti. Any use of the
                above terminology or other words in the singular, plural,<br>
                capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.
            </p>
            <p>Taaj Services&nbsp;is operated from:<br>
                Angle de Rue, de Verdon et Rue de Rayon, Immeuble Marouf, Quartier Comercial, Djibouti, Centre Ville
                Djibouti</p>
            <p>These terms and conditions outline the rules and regulations for the use of the Taaj Services’ Website.
                The information on this website are subject to specific terms and conditions, warnings and restrictions.
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these
                Terms. These Terms apply to all visitors, users and others who access or use the Service. We consider
                these terms as well as the instruction to set out the whole agreement between us for the purpose of
                conducting a transfer. <em>By accessing or using the Service you accept and agree to be bound by the
                    Terms stated on this page. If you disagree with any part of the terms then you may not access the
                    Service and thus shall not use the website.</em></p>
            <p><strong>Your Responsibilities</strong></p>
            <p>You agree that:</p>
            <p>You will not try to access or attempt to use the Service to provide any instructions if you are not over
                the age of 18, and that you have the legal capacity to form a binding legal contract.</p>
            <p>For a transaction to be conducted you must provide us with sufficient information and instructions
                relating to the Transfer you wish to make to enable us to proceed with the transfer in accordance with
                these terms. Failure to provide the necessary information may result in the cancellation of your
                transaction and you will be notified in writing.</p>
            <p>It is your responsibility to check the details in these Terms and that you understand these Terms before
                you proceed with an instruction, because you will be bound by the Terms once a contract comes into
                action between us.</p>
            <p>It is your responsibility to ensure that the beneficiary of your transaction is notified and collects the
                money from our agent. The beneficiary must provide us with valid identification and the unique RID
                number along with the verification code. We may need other requirements to complete the transactions
                depending upon local laws. This is because we are required to comply with Anti-Money Laundering
                legislation and thus you are required to provide us with any information we ask of you such as proof of
                income and identify.</p>
            <p><strong>Reservation of Rights</strong></p>
            <p>TaajServices.com reserves the right and sole discretion to:</p>
            <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any
                particular link to our Web site. You agree to immediately remove all links to our Web site upon such
                request. We also reserve the right to amend these terms and conditions and its linking policy at any
                time. By continuing to link to our Web site, you agree to be bound to and abide by these linking terms
                and conditions.</p>
            <p><strong>User data</strong></p>
            <p>We will maintain certain data that you transmit to the website for the purpose of managing the
                performance of the website, as well as data relating to your use of the website. Although we perform
                regular routine backups of data, you are solely responsible for all data that you transmit or that
                relates to any activity you have undertaken using the website. You agree that we shall have no liability
                to you for any loss or corruption of any such data, and you herby waive any right of action against us
                arising from any such loss or corruption of such data.</p>
            <p><strong>Cookies</strong></p>
            <p>We employ the use of cookies. By using Taaj Services’ website you consent to the use of cookies<br>
                in accordance with Taaj Services’ privacy policy.</p>
            <p>Most of the modern day interactive web sites use cookies to enable us to retrieve user details for each
                visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of
                use for those people visiting. Some of our affiliate / advertising partners may also use cookies. For
                more information on cookies and what actions we take to protect your information, please refer to our
                <strong><a href="/cookie-policy">Cookies Policy</a>&nbsp;</strong>and <a
                    href="/privacy-policy"><strong>Privacy Policy</strong></a>.</p>
            <p><strong>Electronic communication</strong></p>
            <p>Visiting the website, sending us emails, and completing online forms constitute electronic
                communications. You consent to receive electronic communications, and you agree that all agreements,
                notices, disclosures, and other communications we provide to you electronically, via email and on the
                site, satisfy any legal requirement that such communication be in writing.</p>
            <p>You agree:</p>
            <ol>
                <li>Taaj Service reserves the right to monitor all Comments and to remove any Comments which it
                    considers in its absolute discretion to be inappropriate, offensive or otherwise in breach of these
                    Terms and Conditions.</li>
                <li>You warrant and represent that:
                    <ol>
                        <li>You are entitled to post the Comments on our website and have all necessary licenses and
                            consents to do so;</li>
                        <li>The Comments do not infringe any intellectual property right, including without limitation
                            copyright, patent or trademark, or other proprietary right of any third party;</li>
                        <li>The Comments do not contain any defamatory, libellous, offensive, indecent or otherwise
                            unlawful material or material which is an invasion of privacy</li>
                        <li>The Comments will not be used to solicit or promote business or custom or present commercial
                            activities or unlawful activity.</li>
                    </ol>
                </li>
            </ol>
            <p>You hereby grant to&nbsp;Taaj Services’&nbsp;a non-exclusive royalty-free license to use, reproduce,<br>
                edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats
                or media such as testimonials for the website.</p>
            <p><strong>License</strong></p>
            <p>Unless otherwise stated, Taaj Services and/or it’s licensors own the intellectual property rights for<br>
                all material on Taaj Services. All intellectual property rights are reserved. You may view and/or
                print<br>
                pages from https://www.taajservices.com for your own personal use subject to restrictions set in these
                terms and conditions.</p>
            <p>You must not:</p>
            <ol>
                <li>Republish material from <a href="https://www.taajservices.com/">https://www.taajservices.com</a>
                </li>
                <li>Sell, rent or sub-license material from <a
                        href="https://www.taajservices.com/">https://www.taajservices.com</a></li>
                <li>Reproduce, duplicate or copy material from <a
                        href="https://www.taajservices.com/">https://www.taajservices.com</a></li>
                <li>Redistribute content from Taaj Services (unless content is specifically made for redistribution).
                </li>
            </ol>
            <p><strong>Hyperlinking to/from our Content</strong></p>
            <ol>
                <li>The following organizations may link to our Web site without prior written approval:
                    <ol>
                        <li>Government agencies;</li>
                        <li>Search engines;</li>
                        <li>News organizations;</li>
                        <li>Online directory distributors when they list us in the directory may link to our Web site in
                            the same<br>
                            manner as they hyperlink to the Web sites of other listed businesses; and</li>
                        <li>System wide Accredited Businesses except soliciting non-profit organizations, charity
                            shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                        <li>Customers referring us.</li>
                    </ol>
                </li>
            </ol>
            <ol>
                <li>These organizations may link to our home page, to publications or to other Web site information so
                    long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship,
                    endorsement or approval of the linking party and its products or services; and (c) fits within the
                    context of the linking party’s site.</li>
                <li>We may consider and approve in our sole discretion other link requests from the following types of
                    organizations:
                    <ol>
                        <li>dot.com community sites;</li>
                        <li>associations or other groups representing charities, including charity giving sites,</li>
                        <li>online directory distributors;</li>
                        <li>internet portals;</li>
                        <li>accounting, law and consulting firms whose primary clients are businesses; and</li>
                        <li>educational institutions and trade associations.</li>
                    </ol>
                </li>
            </ol>
            <p>We will approve link requests from these organizations if we determine that: (a) the link would not
                reflect unfavourably on us or our accredited businesses (for example, trade associations or other
                organizations representing inherently suspect types of business, such as work-at-home opportunities,
                shall not be allowed to link); (b) the organization does not have an unsatisfactory record with us; (c)
                the benefit to us from the visibility associated with the hyperlink outweighs the absence of&nbsp;; and
                (d) where the link is in the context of general resource information or is otherwise consistent with
                editorial content in a newsletter or similar product furthering the mission of the organization.</p>
            <p>These organizations may link to our home page, to publications or to other Web site information so long
                as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or
                approval of the linking party and It products or services; and (c) fits within the context of the
                linking party’s<br>
                site. If you are among the organizations listed in paragraph 2 above and are interested in linking to
                our website, you must notify us by sending an e-mail to&nbsp;<a
                    href="mailto:info@taajservices.com">info@taajservices.com</a>.<br>
                Please include your name, your organization name, contact information (such as a phone number and/or
                e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our
                Web site, and a list of the URL(s) on our site to which you would like to link. Allow 1 week for a
                response.</p>
            <p>Approved organizations may hyperlink to our Web site as follows:</p>
            <ol>
                <li>By use of our corporate name; or</li>
                <li>By use of the uniform resource locator (Web address) being linked to; or</li>
                <li>By use of any other description of our Web site or material being linked to that makes sense within
                    the context and format of content on the linking party’s site.</li>
            </ol>
            <p>No use of Taaj Services’ logo or other artwork will be allowed for linking absent a trademark license
                agreement.</p>
            <p><strong>Links to Other Websites</strong></p>
            <p>Our site may contain links to third-party web sites or services that are not owned or controlled by Taaj
                Services.</p>
            <p><strong>Removal of links from our website</strong></p>
            <p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact
                us about this. We will consider requests to remove links but will have no obligation to do so or to
                respond directly to you.</p>
            <p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its
                completeness or accuracy; nor do we commit to ensuring that the website remains available or that the
                material on the website is kept up to date.</p>
            <p><strong>Iframes</strong></p>
            <p>Without prior approval and express written permission, you may not create frames around our Web pages or
                use other techniques that alter in any way the visual presentation or appearance of our Web site.</p>
            <p><strong>Content Liability</strong></p>
            <p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to
                indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may
                appear on any page on your Web site or within any context containing content or materials that may be
                interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the
                infringement or other violation of, any third-party rights.</p>
            <p><strong>Governing Law</strong></p>
            <p>These terms and conditions are governed by and construed in accordance with the laws of Djibouti and the
                parties submit to the exclusive jurisdiction of the Djibouti courts.</p>
            <p><strong>Disclaimer</strong></p>
            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and
                conditions relating to our website and the use of this website (including, without limitation, any
                warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of
                reasonable care and skill). Nothing in this disclaimer will:</p>
            <ol>
                <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
            </ol>
            <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer:
                (a)<br>
                are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer
                or<br>
                in relation to the subject matter of this disclaimer, including liabilities arising in contract, in
                tort<br>
                (including negligence) and for breach of statutory duty.</p>
            <p>To the extent that the website and the information and services on the website are provided free of
                charge, we will not be liable for any loss or damage of any nature.</p>
            <p><strong>Changes to the Terms and Conditions</strong></p>
            <p>We reserve the right to make changes or modifications to these Terms at any time and for any reason. We
                will alert you about any changes by updating the “last updated” date of these Terms and Conditions, and
                you waive any right to receive specific notice of each such change. It is your responsibility to review
                these Terms and conditions to stay informed of updates. You will be subject to, and will be deemed to
                have been made aware of and to have accepted, the changes in any revised Terms and Conditions.</p>
            <p><strong>Contact us</strong></p>
            <p>If you have any questions about these Terms, please contact us +971 4 259 4723 or via Email: <a
                    href="mailto:info@taajservices.com">info@taajservices.com</a></p>
        </div>
    </div>
</div>