<div class="container-fluid bg-dark p-5">
    <div class="row">
        <div class="col-md-3">
            <h5 class="text-white">ABOUT TAAJ SERVICES</h5>
            <hr class="bg-secondary">
            <p class="text-light">Taaj services is a worldwide money transfer company with operations in many countries
                and interlink existing payment service institutions throughout the world. Since its incorporation in the
                early 2000’s the company has earned a desirable reputation as a market leader by paying careful
                attention to details. It is one of the fastest growing MSB company that impacts millions of lives every
                day.</p>
            <small><em><a routerLink="about-us">Read more...</a></em></small>
        </div>
        <div class="col-md-3">
            <h5 class="text-white">QUICK LINKS</h5>
            <hr class="bg-secondary">
            <ul class="list-group list-group-flush">
                <li class="list-group-item list-group-item-dark"><a routerLink="findpartners">Find a Partner</a></li>
                <li class="list-group-item list-group-item-dark"><a routerLink="antimoney-laundering">Anti-Money Laundering</a></li>
                <li class="list-group-item list-group-item-dark"><a routerLink="privacy-policy">Privacy Policy</a></li>
                <li class="list-group-item list-group-item-dark"><a routerLink="cookie-policy">Cookie Policy</a></li>
                <li class="list-group-item list-group-item-dark"><a routerLink="terms-conditions">Terms & Conditions</a></li>
            </ul>
        </div>
        <div class="col-md-3">
            <h5 class="text-white">CONTACT US</h5>
            <hr class="bg-secondary">
            <ul class="list-group list-group-flush">
                <li class="list-group-item list-group-item-dark"><i class="fa fa-map-marker fa-lg mr-2"
                        aria-hidden="true"></i>
                    Angle de Rue, de Verdon et Rue de Rayon, Immeuble Marouf, Quartier Comercial, Djibouti, Centre Ville
                    Djibouti</li>
                <!-- <li class="list-group-item list-group-item-dark"><i class="fa fa-map-marker fa-lg mr-2"
                        aria-hidden="true"></i>
                        1303, Twin Towers, Baniyas Road, Deira - Dubai - United Arab Emirates</li>
                        <li class="list-group-item list-group-item-dark"><i class="fa fa-phone fa-lg mr-2"
                            aria-hidden="true"></i>
                            +971 4 259 4723</li> -->
                            <li class="list-group-item list-group-item-dark"><i class="fa fa-envelope fa-lg mr-2"
                                aria-hidden="true"></i>
                                info@taajservices.com</li>
            </ul>
        </div>
        <div class="col-md-3">
            <img src="assets/images/IAMTN-logo.png">
        </div>
    </div>
</div>
<div class="container-fluid bg-green">
<div class="row text-center">
    <div class="col-md-12 mt-3">
        <p>© 2019-2021 - All Rights Reserved - <a href="https://www.taajservices.com" class="text-white">Taaj Services</a></p>
    </div>
</div>
</div>