import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { partnersDataList } from '../../shared/partners-data'

@Component({
  selector: 'app-findpartner',
  templateUrl: './findpartner.component.html',
  styleUrls: ['./findpartner.component.css']
})

export class FindpartnerComponent implements OnInit {
  form!: FormGroup;
  selectedPartners: any = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      partners: [null]
    });
  }

  onSelectPartnerCountry(event: any) {
    console.log(partnersDataList);
    this.selectedPartners = [];
    partnersDataList.forEach(partner => {
      if (partner.partnerCountry === event.target.value) {
        this.selectedPartners.push(partner);
      }
    })
  }

}
