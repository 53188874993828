<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>ACCPA <span class="text-green">Awards</span></h1>
                <h6><a href="news">News</a>  >  Accpa Awards</h6>
            </div>
        </div>
    </div>
</div>
<div class="container my-5">
    <div class="row">
      <div class="col-md-12">
        <small class="text-muted"><i class="fa fa-user-circle fa-lg mr-2"></i><strong>PaySii</strong><span class="mx-2">-</span>2019<span class="mx-2">-</span>2 min read</small><br><br>
      </div>
    </div>
        <div class="row">
          <div class="col-md-10">
            <h2>2019 'Best in AML Compliance' Award</h2>
          </div>
            <div class="col-md-10" >
                <img class="img-fluid" src="assets/images/ACCPA.png">
            </div>
        </div>
        <div class="row">
          <div class="col-md-10 mt-3" >
              <p>
                Taaj was awarded ‘The Best in AML Compliance’ award by the prestigious Association of Certified Compliance Professional in Africa (ACCPA). This year about 500 African remittance companies gathered in South Africa vying to win the prestigious Award, which is Africa’s highest honour to be awarded to a Financial Institution. Eventually, Taaj emerged victorious amongst those companies, where it won the Award. Becoming the first ever Somali-owned company to receive this Award.
              </p>
              <p>
                Every achievement has a beginning, ours begun with hiring adequately trained and certified professionals in the area of Compliance. This, coupled with ensuring that all our staff regularly undertake relevant professional courses to enhance their knowledge within the compliance space. In line with Taaj Management’s recognition of the dynamic nature of Compliance where international standards are constantly changing; the Company made Compliance a top priority in order to maintain the Company’s adherence to International standards and local regulations.
              </p>
              <p>
                As has been stated by a senior member of staff: “in the coming years Taaj will expand and tailor it’s training program to include ongoing changes and updates of local and international regulations”. ‘And this Award will serve as a benchmark for continuing success,’ another senior Manager in the Compliance Department added.
              </p>
              <p>
                Finally, the ACCPA Award is a testimony to our relentless dedication and diligent adherence to both domestic and International Anti-Money Laundering regulations.
              </p>
          </div>
          <div class="col-md-10" >
            <img class="img-fluid" src="assets/images/ACCPA-award.jpeg">
        </div>
      </div>
    </div>