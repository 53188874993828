<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Vision, Mission & <span class="text-green">Values</span></h1>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row my-5 py-4">
        <div class="col-md-12">
            <strong>About Us</strong>
            <p>Taaj services is a worldwide money transfer company with operations in many countries and interlink existing payment service institutions throughout the world. Since its incorporation in the early 2000’s the company has earned a desirable reputation as a market leader by paying careful attention to details.  It is one of the fastest growing MSB company that impacts millions of lives every day. Our locations are within reach wherever and whenever you need to send money to your family, friends and relatives. With 100s of agents available in every continent.</p>
            <strong>Scope of work</strong>
            <p><span class="text-green"><strong><u>MMT:</u></strong></span> We enable our customers to use their mobile device to send money and receive receive money, at their convenience. Our goal is to be apart of the leaders in Mobile Money Transfer.</p>
            <p><span class="text-green"><strong><u>Online Money Transfer:</u></strong></span> Allows our customers to conduct their transactions from the comfort of their homes.</p>
            <p><span class="text-green"><strong><u>Bank Transfer:</u></strong></span> Allowing our customers to deposit received funds to their bank account and notify them once deposited.</p>
            <p><span class="text-green"><strong><u>Traditional Money Transfer:</u></strong></span> The traditional method, providing customers the access to go to one of our conveniently located partners to send and receive Remittance.</p>
            <strong>Vision</strong>
            <p>Our vision is to become a household name, to be the consumers’ first choice for money remittance and foreign exchange.</p>
            <strong>Mission</strong>
            <p>Our mission is to maintain our rapid growth rate while simultaneously staying true to our close-knit relationship with our customers. we pride ourselves on maintaining the same level of customer satisfaction and professionalism throughout every inch of our network, as well as continually staying up-to-date with stakeholders’ needs and wants to provide a superior service.</p>
            <strong>Values</strong>
            <ul>
                <li><strong><u>Ethics:</u></strong> Honesty, commitment to the policies of Taaj and compliance of AML as well as avoiding acts contrary to the code of conduct.</li>
                <li><strong><u>Integrity:</u></strong> Doing what is right to build on our stakeholders trust, respect and loyalty.</li>
                <li><strong><u>Passion:</u></strong> Loving what we do leading to quality, productivity and growth.</li>
                <li><strong><u>security:</u></strong>  Dealing with all private and confidential information appropriately.</li>
                <li><strong><u>Partnership:</u></strong> Maintaining a healthy relationship with our employees and partners is critical to the success of this organization.</li>
                <li><strong><u>Global Involment:</u></strong> Acting without boundaries, making sure to facilitate to our customers in all corners of the world.</li>
            </ul>
            <br>
            <div class="alert alert-success" role="alert">
                <blockquote class="blockquote">
                    <footer class="blockquote-footer"><cite title="Source Title">passionately focusing on…</cite></footer>
                    <p class="mb-0"><strong>S</strong>ervice, <strong>P</strong>roductivity, <strong>E</strong>fficiency, <strong>E</strong>ffectiveness, <strong>D</strong>edication and commitment.</p>
                  </blockquote>
              </div>
        </div>
    </div>
</div>