import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-antimoney',
  templateUrl: './antimoney.component.html',
  styleUrls: ['./antimoney.component.css']
})
export class AntimoneyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
