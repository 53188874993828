<nav class="navbar navbar-expand-lg navbar-light navbar-bg">
    <a class="navbar-brand" href="/"><img src="assets/images/taaj-logo.svg"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Company
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="about-us">About Us</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="vision-mission">Vision, Mission & Values</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="antimoney-laundering">Anti-Money Laundering</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="news">Latest News</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Sending & Receiving
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">taajonline.net</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="check-online-status">Check Online Status</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Partner Portal
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="https://taajpay.net/" target="_blank">Partner Login</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="findpartners">Find Partner</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Become a Partner</a>
            </div>
          </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="faqs">FAQ's</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="contact-us">Contact Us</a>
        </li>
      </ul>
    </div>
  </nav>