<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Find <span class="text-green">Partners</span></h1>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row mt-5 py-4 justify-content-center">
        <div class="col-md-6">
            <form [formGroup]="form" *ngIf="form" id="partnerslist-form">
                <div class="form-group">
                    <select class="form-control form-control-lg" id="partners" name="partners"
                        formControlName="partners" (change)="onSelectPartnerCountry($event)">
                        <option value="null" selected>Select Country</option>
                        <option value="Australia">Australia</option>
                        <option value="Canada">Canada</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Finland">Finland</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Norway">Norway</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="UAE">United Arab Emirates</option>
                        <option value="UK">United Kingdom</option>
                        <option value="US">United States of America</option>
                    </select>
                </div>
            </form>
        </div>
    </div>
    <div class="row mb-5 mt-4" *ngIf="selectedPartners.length > 0">
        <div class="col-sm-6" *ngFor="let sP of selectedPartners; let RowIndex = index">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{sP.partnerName}}</h5>
                    <p class="card-text"><i class="fa fa-map-marker fa-lg mr-2"
                            aria-hidden="true"></i>{{sP.partnerAddress}}</p>
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><i class="fa fa-phone fa-lg mr-2" aria-hidden="true"></i><a
                            href="tel:{{sP.partnerPhone}}"> {{sP.partnerPhone}}</a></li>
                    <li class="list-group-item"><i class="fa fa-map fa-lg mr-2" aria-hidden="true"></i><a
                            href="{{sP.partnerLocation}}" target="_blank">Location on Google Maps</a></li>
                    <li class="list-group-item">
                        <div id="accordion">
                            <i class="fa fa-clock-o fa-lg mr-2" aria-hidden="true"></i><a href="#"
                                data-toggle="collapse" [attr.data-target]="'#partner'+ RowIndex"
                                aria-expanded="true">Working Hours</a>
                            <div id="partner{{RowIndex}}" class="collapse">
                                <table class=" mt-3 table table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Day</th>
                                            <th scope="col">Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sPWH of sP?.partnerWorkingHours">
                                            <td>{{sPWH.WorkingDay}}</td>
                                            <td>{{sPWH.workingHours}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>