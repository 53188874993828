<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Page Not <span class="text-green">Found</span></h1>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row my-5 py-4">
        <div class="col-md-12">
            The page you are looking does not exist...
        </div>
    </div>
</div>