import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visionmission',
  templateUrl: './visionmission.component.html',
  styleUrls: ['./visionmission.component.css']
})
export class VisionmissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
