<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Cookie <span class="text-green">Policy</span></h1>
            </div>
        </div>
    </div>
</div>
<div class="container my-5">
    <div class="row">
        <div class="col-md-12">
            <em class="text-muted"><span>Last Updated: May 2018</span></em><br><br>
        </div>
        <div class="col-md-12">
            <p>Taaj Services (“us”, “we”, or “our”) uses Cookies on www.taajservices.com (the “Service”). By using the
                Service, you thereby consent to the use of Cookies.</p>
            <p>Our Cookie Policy explains what cookies are and how we use them. You should read this policy so you can
                have a better understanding of the type of Cookies we use, the information we gather using cookies and
                how that information is used.</p>
            <p>When you visit our website, or use a mobile app, we will place one or more Cookies on your device or use
                JavaScript, HTML 5 and other digital technologies to collect information from your computer, mobile
                handset or&nbsp;other device. This information may contain personal data. In this Cookie policy, we will
                tell you what Cookies we use and what their functions are. How we process personal data that we obtain
                from your device is described in our&nbsp;Privacy Policy.&nbsp;When you visit our website, we will
                regard this as your consent and your specific request to provide you with the full functionality of the
                site.</p>
            <p><strong>What are Cookies?</strong></p>
            <p>Cookies are small (temporary) text files we transfer to your device. We may also use similar digital
                techniques, such as JavaScript, HTML 5 etc. We refer to these digital techniques as “cookies” in this
                cookies policy.</p>
            <p>A cookie file is stored in your web browser and allows the Service to recognize you and make future
                visits easier and the Service more useful to you.</p>
            <p>There are two types of cookies to consider, these; “persistent” or “session” cookies.</p>
            <p><strong>Why do we use cookies?</strong></p>
            <p>We use Cookies to improve your user experience by identifying content usage, information about your
                preferences and patterns as well as to support the marketing of our services. Cookies will allow us to
                recognise and count the number of visitors we get that use the site and also see how those visitors move
                around.</p>
            <p>Examples of Cookies we use:</p>
            <ul>
                <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.
                </li>
                <li>Security Cookies. We use Security Cookies for security purposes.</li>
            </ul>
            <p>Please note that the information gathered is for our us only. We do not disclose or share this with any
                third parties.</p>
            <p><strong>Third-party cookies</strong></p>
            <p>Third party cookies are used to gather information about you. We would use various third-party cookies to
                report on usage statistics of the Service, deliver advertisements on and through the Service and so on.
                We do not control the operation of any of them. The third-party cookies which may be set include:</p>
            <ul>
                <li>Google Analytics – we use Google Analytics to collect data about website usage. This data does not
                    include personally identifiable information. You can view the Google Privacy Policy here: <a
                        href="https://www.google.com/policies/privacy" target="_blank"
                        rel="noopener">www.google.com/policies/privacy/</a>.</li>
                <li>Tawk.to&nbsp;– we use talk.to, a live chat solution provided by task.to Ltd. Tawk.to uses cookies to
                    help us identify and track visitors. The chat log may be saved with your personal profile in order
                    for us to give you excellent customer service. You can view the Task.to Privacy Policy here:<br>
                    www.tawk.to/privacy-policy/.</li>
            </ul>
            <p><strong>Manage your cookies</strong></p>
            <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of
                all or some cookies. However, if you use your browser settings to block all cookies (including essential
                cookies) you may not be able to access all or parts of our site.</p>
            <p>If you’d like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit
                the help pages of your web browser.</p>
            <p>Depending on the browser you are using, there are different methods for managing cookies. Take a look at
                the instructions bellow provided by the web browsers manufacturers directly, in order to configure your
                browser settings*.</p>
            <p><a href="https://support.microsoft.com/en-gb/products/windows?os=windows-10" target="_blank"
                    rel="noopener">Microsoft Internet Explorer (IE)</a></p>
            <p><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Firefox</a>
            </p>
            <p><a href="https://support.apple.com/kb/PH5042?locale=en_GB">Safari</a></p>
            <p><a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></p>
            <p>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use
                all of the features we offer, you may not be able to store your preferences, and some of our pages might
                not display properly.</p>
            <p><em>*These links direct you to third party which we have no control – no liability can be claimed if they
                    are inaccurate.</em></p>
            <p><strong>Learn more</strong></p>
            <p>You can learn more about Cookies and the following third-party websites:</p>
            <ul>
                <li><a href="https://www.allaboutcookies.org/">AllAboutCookies</a></li>
                <li><a href="https://www.networkadvertising.org/">Network Advertising Initiative&nbsp;</a></li>
            </ul>
        </div>
    </div>
</div>