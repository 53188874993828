import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { VisionmissionComponent } from './components/visionmission/visionmission.component';
import { AntimoneyComponent } from './components/antimoney/antimoney.component';
import { FindpartnerComponent } from './components/findpartner/findpartner.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { CookiepolicyComponent } from './components/cookiepolicy/cookiepolicy.component';
import { AccpaawardComponent } from './components/news/accpaaward/accpaaward.component';
import { NewsComponent } from './components/news/news.component';
import { CheckonlinestatusComponent } from './components/checkonlinestatus/checkonlinestatus.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieLawModule } from 'angular2-cookie-law';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutusComponent,
    ContactusComponent,
    PagenotfoundComponent,
    VisionmissionComponent,
    AntimoneyComponent,
    FindpartnerComponent,
    FaqsComponent,
    TermsComponent,
    PrivacypolicyComponent,
    CookiepolicyComponent,
    AccpaawardComponent,
    NewsComponent,
    CheckonlinestatusComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CookieLawModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
