export const partnersDataList = [
    {
        partnerCode: 'AUSYD',
        partnerCountry: 'Australia',
        partnerName: 'Himilo Enterprise PTY. LTD.',
        partnerAddress: '25A The Mall Bell St, Heidelberg West, VIC, 3081, AU',
        partnerPhone: '(+61) 435571873',
        partnerLocation: 'https://www.google.ae/maps/place/25+Bell+St,+Heidelberg+VIC+3084,+Australia/@-37.754705,145.055529,17z/data=!4m13!1m7!3m6!1s0x6ad64686aee8e25b:0xc39bf6d8e93b6a0!2s25+Bell+St,+Heidelberg+VIC+3084,+Australia!3b1!8m2!3d-37.7546927!4d145.0554353!3m4!1s0x6ad64686aee8e25b:0xc39bf6d8e93b6a0!8m2!3d-37.7546927!4d145.0554353?hl=en',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Friday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Sunday', workingHours: 'Closed'}
        ]
    },
    {
        partnerCode: 'AUSYN',
        partnerCountry: 'Australia',
        partnerName: 'Wacan Express PTY. LTD.',
        partnerAddress: '1/135 Nicholson Street, Footscray, VIC, 3011, AU',
        partnerPhone: '(+61) 432701259',
        partnerLocation: 'https://goo.gl/maps/rk8Q6EgH8qJ2',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Friday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Sunday', workingHours: 'Closed'}
        ]
    },
    {
        partnerCode: 'CASAR',
        partnerCountry: 'Canada',
        partnerName: 'Sarifle INC.',
        partnerAddress: 'Suite 202 -2083 Lawrence AVE W, Toronto, Ontario, Canada, M9N 1H7.',
        partnerPhone: '(+416) 8369535',
        partnerLocation: 'https://goo.gl/maps/bKRbjD6gVjq',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '09:00 AM - 16:00 PM'}
        ]
    },
    {
        partnerCode: 'CADMN',
        partnerCountry: 'Canada',
        partnerName: 'Nageye',
        partnerAddress: '2093 Weston Road. Toronto, Ontario, Canada, M9 4Y3',
        partnerPhone: '(+647) 7173008',
        partnerLocation: 'https://goo.gl/maps/FciUgjyB3Sq',
        partnerWorkingHours: [
            
        ]
    },
    {
        partnerCode: 'DNENT',
        partnerCountry: 'Denmark',
        partnerName: 'Vester Exchange',
        partnerAddress: 'Heimdalsgade 10, 2200 KBH N, Denmark',
        partnerPhone: '(+45) 35840301',
        partnerLocation: 'https://goo.gl/maps/PvD5cWmB2sD2',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '09:00 AM - 16:00 PM'}
        ]
    },
    {
        partnerCode: 'DJIBO',
        partnerCountry: 'Djibouti',
        partnerName: 'Taaj Financial Service',
        partnerAddress: 'Commercial Zone, Rue De Paris',
        partnerPhone: '(+253) 21353515',
        partnerLocation: 'https://goo.gl/maps/JdKYwpCAYjz',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '09:00 AM - 16:00 PM'}
        ]
    },
    {
        partnerCode: 'ETADI',
        partnerCountry: 'Ethiopia',
        partnerName: 'Taaj Financial Service',
        partnerAddress: 'Bole, Addis Ababa, Ethiopia',
        partnerPhone: '(+251) 11392043',
        partnerLocation: '',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '09:00 AM - 16:00 PM'}
        ]
    },
    {
        partnerCode: 'FNHQR',
        partnerCountry: 'Finland',
        partnerName: 'Roble Services Oy',
        partnerAddress: 'Myllypurontie 22, 00920 Helsinki, Finland',
        partnerPhone: '(+35) 8469314124',
        partnerLocation: 'https://goo.gl/maps/NezdLHnWcjv',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Friday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '10:00 AM - 20:00 PM'}
        ]
    },
    {
        partnerCode: 'KEHQR',
        partnerCountry: 'Kenya',
        partnerName: 'Taaj Money Transfer',
        partnerAddress: 'Gulf Palace Kipande Athumani St, Eastleigh, Kenya',
        partnerPhone: '(+254) 715559407',
        partnerLocation: 'https://goo.gl/maps/fq4WrKfsZm62',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Friday', workingHours: 'Closed'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '09:00 AM - 17:00 PM'},
        ]
    },
    {
        partnerCode: 'NOOSL',
        partnerCountry: 'Norway',
        partnerName: 'TTC Finans AS',
        partnerAddress: 'Postboks 9134, Gronland, 0133 Oslo, Norway',
        partnerPhone: '(+47) 40047981',
        partnerLocation: 'https://goo.gl/maps/1aRJQaSnKno',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '09:00 AM - 16:00 PM'}
        ]
    },
    {
        partnerCode: 'NOSLO',
        partnerCountry: 'Norway',
        partnerName: 'Safari AS ',
        partnerAddress: 'Gronlandsleiret 25, 0190, Oslo, Norway',
        partnerPhone: '(+47) 91008850',
        partnerLocation: 'https://goo.gl/maps/a93ES2pPYo52',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Sunday', workingHours: 'Closed'},
        ]
    },
    {
        partnerCode: 'SWMY',
        partnerCountry: 'Sweden',
        partnerName: 'Sahmiye AB',
        partnerAddress: 'Farogatan 33, 164 51 Kista, Sweden',
        partnerPhone: '(+46) 720182016',
        partnerLocation: 'https://goo.gl/maps/Q31WEiQejb72',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 16:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '09:00 AM - 16:00 PM'}
        ]
    },
    {
        partnerCode: 'SWMSM',
        partnerCountry: 'Sweden',
        partnerName: 'Malsom AB',
        partnerAddress: 'Marieholmsagatan 42, 415 22 Goteborg, Sweden',
        partnerPhone: '(+46) 753815494',
        partnerLocation: 'https://goo.gl/maps/FyKucyBBuKM2',
        partnerWorkingHours: [
        
        ]
    },
    {
        partnerCode: 'CHMGG',
        partnerCountry: 'Switzerland',
        partnerName: 'Medina Global LTD.',
        partnerAddress: 'Riedmuhlestrasse 8, 8305 Dietlikon, Switzerland',
        partnerPhone: '(+41) 445002568',
        partnerLocation: 'https://goo.gl/maps/oJBT7cydtT22',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Sunday', workingHours: 'Closed'},
        ]
    },
    {
        partnerCode: 'CHZUR',
        partnerCountry: 'Switzerland',
        partnerName: 'SomRemit',
        partnerAddress: 'Fabrikstrasse 11, 8005 ZÙrich',
        partnerPhone: '(+417) 62462000',
        partnerLocation: 'https://goo.gl/maps/HzxMUqd5hrdHUJ179',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Friday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Saturday', workingHours: '10:00 AM - 20:00 PM'},
            {WorkingDay: 'Sunday', workingHours: 'Closed'}
        ]
    },
    {
        partnerCode: 'UADUB',
        partnerCountry: 'UAE',
        partnerName: 'Sundus Exchange',
        partnerAddress: '12/13, G floor, Al Owais Building, Baniyas Road, Deira, Dubai UAE',
        partnerPhone: '(+971) 4 295 8916',
        partnerLocation: 'https://goo.gl/maps/roc6P3H7ad62',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 21:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 21:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 21:00 PM'},
            {WorkingDay: 'Friday', workingHours: 'Closed'},
            {WorkingDay: 'Saturday', workingHours: '09:00 AM - 21:00 PM'},
            {WorkingDay: 'Sunday', workingHours: '09:00 AM - 21:00 PM'},
        ]
    },
    {
        partnerCode: 'UKCMD',
        partnerCountry: 'UK',
        partnerName: 'Tayo Services',
        partnerAddress: '60 Parkway,  London, NW1 7AH',
        partnerPhone: '(+44) 2074248810',
        partnerLocation: 'https://goo.gl/maps/URezhZfGrhT2',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Saturday', workingHours: 'Closed'},
            {WorkingDay: 'Sunday', workingHours: 'Closed'},
        ]
    },
    {
        partnerCode: 'UKIMN',
        partnerCountry: 'UK',
        partnerName: 'Hogmall LTD.',
        partnerAddress: '83 High Street, Harlesden, London, England, NW10 4NT',
        partnerPhone: '(+44) 7946766558',
        partnerLocation: 'https://goo.gl/maps/dDCG7hMHVn75feBF7',
        partnerWorkingHours: [
           
        ]
    },
    {
        partnerCode: 'NAMT',
        partnerCountry: 'US',
        partnerName: 'World Direct Link',
        partnerAddress: '5405 Memorial Drive Suite A104, Stone Mountain, GA, 30083  ',
        partnerPhone: '(+167) 886912',
        partnerLocation: 'https://goo.gl/maps/xAGcdYyP52S2',
        partnerWorkingHours: [
            {WorkingDay: 'Monday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Tuesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Wednesday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Thursday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Friday', workingHours: '09:00 AM - 17:00 PM'},
            {WorkingDay: 'Saturday', workingHours: 'Closed'},
            {WorkingDay: 'Sunday', workingHours: 'Closed'},
        ]
    }
]