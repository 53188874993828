<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Anti Money <span class="text-green">Laundering</span></h1>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row my-5 py-4">
        <div class="col-md-12">
           <p>
            Taaj is a money transfer company (hereinafter referred to as “Taaj”) who are committed to fight against Money Laundering and Terrorist Financing activities (hereinafter referred to as “ML/TF”).  We shield our services, customers and our employees, from engaging and/or enabling ML/TF. 
           </p>
           <strong>Policy</strong><br><br>
           <ul>
               <li>Taaj Shall comply with international, local and legal regulatory requirements</li>
               <li>Taaj shall not accept any customers involved in ML/TF </li>
               <li>Taaj shall implement internal policies and procedures to counter ML/TF activities devised by our Money Laundering Reporting Officer (MLRO) and approved by Senior Managements of Taaj.</li>
           </ul>
           <strong>Management Framework </strong><br><br>
           <p>In order to establish an appropriate framework to prevent ML/TF, Taaj Shall implement the following measures:</p>
           <ul>
               <li>Establish and develop organisational structure, policies and procedures for preventing ML/TF</li>
               <li>Training to ensure all involved with Taaj understand the importance of preventing ML/TF and to ensure they are aware of their roles and responsibilities in the framework</li>
               <li>To assess and sustain the status of compliance within the framework of ML/Tf prevention</li>
           </ul>
           <p>We recognise the importance of having effective policies and procedures in place to protect our services being used as a vehicle for facilitating terrorist financing and laundering of illicit crime proceeds. Therefore, we have developed a comprehensive Anti-Money Laundering Programme that is used throughout our networks to ensure full compliance with all relevant guidelines and regulation: Including the Financial Action Task Force (FATF) 40 recommendations; and the recommendations of Basel Committee, among others.</p>
           <p>In addition to that, our AML manual has also been incorporated with the requirements related to Enhanced Customer Due Diligence (ECDD) on specific customers and activities to ensure effective Anti-Money Laundering (AML) and Countering Financing of Terrorism (CFT) controls in line with international standards to be implemented throughout the organisation. Moreover, Taaj Management recognises the risks associated with money laundering and the detrimental effect it would have on the Company. Therefore, our staff are regularly trained and updated on Risk Approach, Customer Due Diligence (CDD) and Know Your Customer (KYC) policies and procedures to make sure that they properly understand and qualified to implement company policy on those areas. Further to that, our Anti-Money Laundering (AML) and Countering Financing of Terrorism (CFT) training program, not only explains the relevant laws and regulations but also covers the internal policies and procedures to mitigate the risk of ML and TF.</p>
           <p>Finally, our AML manual requires total compliance by all our staff and agents and is subject to periodic reviews in order to ensure we apply the latest and best International practices regarding the fight against Anti-Money Laundering and Terrorist Financing activities.</p>
        </div>
    </div>
</div>