<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Contact <span class="text-green">Us</span></h1>
                <h6>We would love to hear from you and see how we can help</h6>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row my-5 py-4">
        <div class="col-md-5">
            <h5>Come Find Us at</h5>
            <ul class="list-group list-group-flush mt-4">
                <li class="list-group-item"><i class="fa fa-map-marker fa-lg mr-2" aria-hidden="true"></i>
                    Angle de Rue, de Verdon et Rue de Rayon,<br>Immeuble Marouf, Quartier Comercial,<br>Djibouti, Centre Ville
                    Djibouti.</li>
                <!-- <li class="list-group-item"><i class="fa fa-map-marker fa-lg mr-2" aria-hidden="true"></i>
                    1303, Twin Towers, Baniyas Road,<br>Deira - Dubai - United Arab Emirates.</li> -->
                <!-- <li class="list-group-item"><i class="fa fa-phone fa-lg mr-2" aria-hidden="true"></i>
                    <a href="tel:+971 4 259 4723"> +971 4 259 4723</a></li> -->
                <li class="list-group-item"><i class="fa fa-envelope fa-lg mr-2" aria-hidden="true"></i>
                    <a href="mailto:info@taajservices.com">info@taajservices.com</a></li>
            </ul>
        </div>
        <div class="col-md-7">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14432.513361884514!2d55.30003276695429!3d25.26626778386409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f433661ee2cfb%3A0x41cafba46e7b8d75!2sDeira%20Twin%20Towers!5e0!3m2!1sen!2sus!4v1618484602360!5m2!1sen!2sus" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</div>