<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>About <span class="text-green">Us</span></h1>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row my-5 py-4">
        <div class="col-md-6">
            <img class="img-fluid news-thumbnail" src="assets/images/our-vision.png">
        </div>
        <div class="col-md-6 my-auto">
            <h2>OUR VISION</h2>
            <p>Our vision is to become a household name, to be the consumers’ first choice for money remittance. We are
                dedicated to spreading our global presence even further in order to reach more people around the world.
            </p>
        </div>
    </div>
    <div class="row my-5 py-4">
        <div class="col-md-6 my-auto">
            <h2>OUR BRAND</h2>
            <p>We've recently redesigned our Logo as part of our worldwide initiative to revamp the company' image to become a powerhouse in the remittance industry.
            </p>
        </div>
        <div class="col-md-6">
            <img class="img-fluid news-thumbnail" src="assets/images/our-brand.jpeg">
        </div>
    </div>
    <div class="row my-5 py-4">
        <div class="col-md-6">
            <img class="img-fluid news-thumbnail" src="assets/images/our-works.jpg">
        </div>
        <div class="col-md-6 my-auto">
            <h2>OUR WORK</h2>
            <p><span class="text-green"><strong><u>MMT:</u></strong></span> We enable our customers to use their mobile device to send money and receive receive money, at their convenience. Our goal is to be apart of the leaders in Mobile Money Transfer.</p>
            <p><span class="text-green"><strong><u>Online Money Transfer:</u></strong></span> Allows our customers to conduct their transactions from the comfort of their homes.</p>
            <p><span class="text-green"><strong><u>Bank Transfer:</u></strong></span> Allowing our customers to deposit received funds to their bank account and notify them once deposited.</p>
            <p><span class="text-green"><strong><u>Traditional Money Transfer:</u></strong></span> The traditional method, providing customers the access to go to one of our conveniently located partners to send and receive Remittance.</p>
        </div>
    </div>
    <div class="row my-5 py-4">
        <div class="col-md-6 my-auto">
            <h2>OUR BELIEFS</h2>
            <p>Our team is full of smart, driven and enthusiastic individuals that have come together to better your experience with us.  Our core values at all levels of the hierarchy remains constant and is practiced throughout. We focus heavily on SPEED: Service, Productivity, Efficiency, Effectiveness, Dedication and commitment.
            </p>
        </div>
        <div class="col-md-6">
            <img class="img-fluid news-thumbnail" src="assets/images/our-beliefs.jpg">
        </div>
    </div>
</div>