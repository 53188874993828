<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img class="d-block w-100" src="assets/images/sliderEVC.jpg" alt="First slide">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100" src="assets/images/sliderMpesa.jpg" alt="Second slide">
        </div>
        <div class="carousel-item">
            <img class="d-block w-100" src="assets/images/sliderZaad.jpg" alt="Third slide">
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
<div class="container our-services">
    <div class="row mt-5 mb-4">
        <div class="col-md-12 text-center">
            <h3 class="section-title">OUR SERVICES</h3>
            <hr class="section-hr">
        </div>
    </div>
    <div class="row text-center ">
        <div class="col-md-4">
            <i class="fa fa-user fa-3x my-4"></i>
            <h5>TMT</h5>
            <p>With TMT, sending money to friends and family through traditional cash deposits has never been easier.
                Simply find your nearest agent and in less than 10 minutes you can send remittance back home.</p>
        </div>
        <div class="col-md-4">
            <i class="fa fa-mobile fa-3x my-4"></i>
            <h5>MMT</h5>
            <p>Taaj is very proud to be apart of the rapidly growing Mobile Money Transfer market in Africa. with
                service’s such from EVC Plus, M-Pesa, ZAAD & SAHAL. You can come to any Taaj location and with the
                necessary information, money can be instantly credited to your mobile. Click here to learn more about
                the electronic wallet services we provide. </p>
        </div>
        <div class="col-md-4">
            <i class="fa fa-university fa-3x my-4"></i>
            <h5>Bank</h5>
            <p>Use Taaj to make instant deposits to Salaam Bank. Trusted by many, you can use our reliable and secure
                service to deposit straight to bank accounts with affordable rates.</p>
        </div>
    </div>
</div>
<div class="my-5"></div>
<div class="container our-services">
    <div class="row mt-5 mb-4">
        <div class="col-md-12 text-center">
            <h3 class="section-title">WHAT ELSE WE PROVIDE?</h3>
            <hr class="section-hr">
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <img class="img-fluid news-thumbnail my-3" src="assets/images/foreign-exchange.jpg">
            <h5>Foreign Exchange</h5>
            <p>Whether you are traveling abroad for business or leisure and need foreign currency, we have got you
                covered. You can buy and sell foreign currency conveniently at a number of our locations dedicated to
                giving you the best rates.</p>
        </div>
        <div class="col-md-4">
            <img class="img-fluid news-thumbnail my-3" src="assets/images/customer-service.jpg">
            <h5>Customer Service</h5>
            <p>We pride ourselves on our outstanding customer service. We make sure we are with you every step of the
                way. Our customer service look forward to helping you with anything you might need. <a
                routerLink="contact-us">Contact Us</a></p>
        </div>
        <div class="col-md-4">
            <img class="img-fluid news-thumbnail my-3" src="assets/images/security.jpg">
            <h5>Security</h5>
            <p>Everyday we make sure your money is in safe hands. Our dedicated team of engineers make sure our systems
                are running accordingly at all times so that we are there when you need us.</p>
        </div>
    </div>
</div>
<div class="my-5"></div>
<div class="container-fluid testimonials text-white pb-5">
    <div class="row">
        <div class="col-md-12 pt-5 pb-3 text-center section-title">
            <h3 class="section-title">WHAT OUR CUSTOMERS HAVE TO SAY</h3>
            <hr class="section-hr">
        </div>
    </div>
    <div class="row pb-5 pt-3 mx-auto-5 justify-content-center">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner text-center">
                <div class="carousel-item active">
                    <img alt="image" src="assets/images/testimonial1.png" width="60">
                    <h4 class="mt-4">Customer</h4>
                    <h6>“I didn’t think it would be this easy. The tellers have been very helpful”</h6>
                </div>
                <div class="carousel-item">
                    <img alt="image" src="assets/images/Testimonial2.png" width="60">
                    <h4 class="mt-4">Customer</h4>
                    <h6>“Ive been using Taaj for a long time now for both business and personal. They’re like my
                        family.”</h6>
                </div>
                <div class="carousel-item">
                    <img alt="image" src="assets/images/testimonial3.png" width="60">
                    <h4 class="mt-4">Customer</h4>
                    <h6>“There is a Taaj agent near my home, I like that I am able to send money quickly and
                        conveniently!”</h6>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</div>
<div class="my-5"></div>
<div class="container">
    <div class="row my-5 py-3">
        <div class="col-md-6 text-center">
            <h3 class="section-title">ABOUT US</h3>
            <hr class="section-hr">
            <p>Taaj services is a worldwide money transfer company with operations in many countries and interlink
                existing payment service institutions throughout the world. Since its incorporation in the early 2000’s
                the company has earned a desirable reputation as a market leader by paying careful attention to details.
                It is one of the fastest growing MSB company that impacts millions of lives every day. Our locations are
                within reach wherever and whenever you need to send money to your family, friends and relatives. With
                100s of agents available in every continent.</p>
            <small><em><a routerLink="about-us">Read more...</a></em></small>
        </div>
        <div class="col-md-6">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/O79632lNviI"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
    </div>
</div>
<div class="my-5"></div>
<div class="container-fluid bg-light">
    <div class="container">
        <div class="row mt-5 py-5">
            <div class="col-md-12 text-center">
                <h3 class="section-title">CUSTOMER SATISFACTION</h3>
                <hr class="section-hr mb-5">
            </div>
            <div class="col-md-6 my-3">
                <p>SPEED<span class="float-right">90%</span></p>
                <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 90%" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-md-6 my-3">
                <p>CUSTOMER SERVICE<span class="float-right">93%</span></p>
                <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 93%" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-md-6 my-3">
                <p>SERVICES<span class="float-right">87%</span></p>
                <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 87%" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="col-md-6 my-3">
                <p>SECURITY<span class="float-right">92%</span></p>
                <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" style="width: 92%" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-dark text-white">
    <div class="row pb-5">
        <div class="col-md-12 pt-5 pb-3 text-center section-title">
            <h3 class="section-title">DO YOU WANT TO...</h3>
            <hr class="section-hr">
        </div>
        <div class="col-md-3 text-center"></div>
        <div class="col-md-3 text-center">
            <h5>Speak to customer service</h5>
            <button class="btn btn-outline-success btn-lg mr-2" >CALL US</button>
        </div>
        <div class="col-md-3 text-center">
            <h5>Become an agent?</h5>
            <button class="btn btn-outline-success btn-lg mr-2" >APPLY TODAY</button>
        </div>
        <div class="col-md-3 text-center"></div>
    </div>
    </div>
<div class="container">
    <div class="row my-5 py-3">
        <div class="col-md-12 text-center">
            <h3 class="section-title">OUR PARTNERS</h3>
            <hr class="section-hr">
            <p>We provide you with exceptional services that leaves all the hassle of sending remittance to us.<br>With
                worldwide partners and a name you can trust, Try us today!</p>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-md-3 text-center">
            <img class="img-fluid news-thumbnail" src="assets/images/mpesa.jpg">
        </div>
        <div class="col-md-3 text-center">
            <img class="img-fluid news-thumbnail" src="assets/images/Zaad.jpg">
        </div>
        <div class="col-md-3 text-center">
            <img class="img-fluid news-thumbnail" src="assets/images/evcplus.jpg">
        </div>
        <div class="col-md-3 text-center">
            <img class="img-fluid news-thumbnail" src="assets/images/sundus1.jpg">
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-md-3 text-center">
            <img class="img-fluid news-thumbnail" src="assets/images/salaam2.jpg">
        </div>
        <div class="col-md-3 text-center">
            <img class="img-fluid news-thumbnail" src="assets/images/telesom.jpg">
        </div>
        <div class="col-md-3 text-center">
            <img class="img-fluid news-thumbnail" src="assets/images/hormuud-telecom.jpg">
        </div>
        <div class="col-md-3 text-center">
            <img class="img-fluid news-thumbnail" src="assets/images/golis.jpg">
        </div>
    </div>
</div>