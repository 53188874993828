import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { AntimoneyComponent } from './components/antimoney/antimoney.component';
import { CheckonlinestatusComponent } from './components/checkonlinestatus/checkonlinestatus.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { CookiepolicyComponent } from './components/cookiepolicy/cookiepolicy.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FindpartnerComponent } from './components/findpartner/findpartner.component';
import { HomeComponent } from './components/home/home.component';
import { AccpaawardComponent } from './components/news/accpaaward/accpaaward.component';
import { NewsComponent } from './components/news/news.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { TermsComponent } from './components/terms/terms.component';
import { VisionmissionComponent } from './components/visionmission/visionmission.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about-us',
    component: AboutusComponent
  },
  {
    path: 'vision-mission',
    component: VisionmissionComponent
  },
  {
    path: 'check-online-status',
    component: CheckonlinestatusComponent
  },
  {
    path: 'antimoney-laundering',
    component: AntimoneyComponent
  },
  {
    path: 'findpartners',
    component: FindpartnerComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacypolicyComponent
  },
  {
    path: 'cookie-policy',
    component: CookiepolicyComponent
  },
  {
    path: 'terms-conditions',
    component: TermsComponent
  },
  {
    path: 'faqs',
    component: FaqsComponent
  },
  {
    path: 'contact-us',
    component: ContactusComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'news/accpa-award',
    component: AccpaawardComponent
  },
  {
    path: '**',
    component: PagenotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
