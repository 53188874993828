<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Latest <span class="text-green">News</span></h1>
                <h6>Get all updated news for Taaj Services</h6>
            </div>
        </div>
    </div>
</div>
<div class="container my-5">
    <div class="row my-5 py-5">
        <div class="col-md-4">

            <a routerLink="/news/accpa-award"><img
                    class="img-fluid news-thumbnail py-3" src="assets/images/ACCPA.png"></a>

        </div>
        <div class="col-md-8">
            <a routerLink="/news/accpa-award">
                <h2 class="news-heading">ACCPA Award</h2>
            </a>
            <small class="text-muted"><em>2019</em></small><br><br>
            <p>
                High-level dignitaries including the Deputy Speaker of the FPS, the Minister of Finance, the Minister of Post, Telecom and Technology, the Minister of Information among others have attended this event to celebrate the ACCPA 2019 compliance awards that Taaj has won.
            </p>
            <a class="btn btn-outline-success round-btn my-2 my-sm-0"
                routerLink="/news/accpa-award">Read More</a>
        </div>
    </div>
</div>