<div class="container-fluid bg-light">
    <div class="container">
        <div class="row py-4">
            <div class="col-md-12 page-title">
                <h1>Privacy <span class="text-green">Policy</span></h1>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row my-5 py-4">
        <div class="col-md-12">
            <em class="text-muted"><span>Effective: May 2018</span></em><br><br>
        </div>
        <div class="col-md-12">
            <p>This Privacy Policy (“Policy”), applies to Taaj Services (the “Service”).</p>
            <p>This Policy explains to you how we collect and use the Personal Data you provide. We ask that you read
                this Privacy Policy carefully as it contains important information on who we are, how and why we
                collect, store, use and share your personal information, your rights in relation to your personal
                information and how to contact us in the event you have a complaint.</p>
            <p><strong>Definition:</strong></p>
            <p>“We”, “Our”, and “Us”, stands for Taaj Services</p>
            <p>“You” and “Your”. Stands for the individual visiting this site and using our services.</p>
            <p>‘Personal Information’ is information which identifies you or can be used to identify you, such as your
                name, contact details, and transaction history. Protecting the confidentiality of your information has
                always been very important to Taaj Services.</p>
            <p><strong>Introduction<br>
                </strong>We use your data to provide and improve the Service. By using the Service, you agree to the
                collection and use of information in accordance with this policy</p>
            <p><em>This Privacy Policy is in accordance with EU General Data Protection Regulation.</em></p>
            <p><strong>What information is being collected<br>
                </strong>We collect several different types of information for various purposes to provide and improve
                our Service to you.</p>
            <p>We collect, use and disclose the following:</p>
            <p>Personal Data<br>
                While using our Service, we may ask you to provide us with certain personally identifiable information
                that can be used to contact or identify you (“Personal Data”). This includes;</p>
            <ul>
                <li>First name and last name</li>
                <li>Passport ID</li>
                <li>Date of birth</li>
                <li>Phone number</li>
                <li>Email address</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Cookies and Usage Data</li>
            </ul>
            <p>As well as information about your transaction with us such as payment history, parties to transactions
                and location</p>
            <p>Usage Data<br>
                We may also collect information on how the Service is accessed and used (“Usage Data”). This Usage Data
                may include information such as your computer’s Internet Protocol address (e.g. IP address), browser
                type, browser version, the pages of our Service that you visit, the time and date of your visit, the
                time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p>Tracking Cookies Data<br>
                We use cookies and similar tracking technologies to track the activity on our Service and hold certain
                information.<br>
                Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies
                are sent to your browser from a website and stored on your device. Tracking technologies also used are
                beacons, tags, and scripts to collect and track information and to improve and analyse our Service.<br>
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However,
                if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <p><em>For more information on Cookies please refer to our <a
                        href="https://www.taajservices.com/cookie-policy/">Cookies Policy</a>.</em></p>
            <p><strong>How we collect information</strong></p><strong>
            </strong>
            <p><strong></strong>We store Information you give us by filling out forms on our site such as (e.g.: our
                ‘Contact us’ section), communicating with us via telephone or filling out an application form.</p>
            <p><strong>Transfer of Data</strong></p>
            <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located
                outside of your state, province, country or other governmental jurisdiction where the data protection
                laws may differ than those from your jurisdiction.</p>
            <p>If you are located outside European Economic Area (“EEA”) and choose to provide information to us, please
                note that we transfer the data, including Personal Data, to EEA and process it there. This may also mean
                that your data could be processed by staff operating outside the EEA who for the us.</p>
            <p>Tajservices.com will take all steps reasonably necessary to ensure that your data is treated securely and
                in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of your
                data and other personal information.</p>
            <p>Your consent to this Privacy Policy followed by your submission of such information represents your
                agreement to that transfer.</p>
            <p><strong>Why is data being collected?</strong></p>
            <p>We collect this information to enhance our user experience and better deliver quality of service to you.
            </p>
            <p>Taajservices.com uses the collected data for various purposes:</p>
            <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide customer care and support</li>
                <li>To provide analysis or valuable information so that we can improve the Service</li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
            </ul>
            <p><strong>How will your gathered data be used?</strong></p>
            <p>We may collect technical information about your visit such as internet Protocol Address (IP);</p>
            <p>We will not disclose any of our customers’ information to third-parties nor will we use this information
                for purposes other than to maintain customers’ database or to update customers on service changes.</p>
            <p><strong>How we protect your information</strong></p>
            <p>The security of your data is important to us, but remember that no method of transmission over the
                Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable
                means to protect your Personal Data, we cannot guarantee its absolute security.</p>
            <p><strong>Legal Requirements</strong></p>
            <p>Taajservices.com may disclose your Personal Data in the good faith belief that such action is necessary
                to:</p>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of Taajservices.com</li>
                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>To protect the personal safety of users of the Service or the public</li>
                <li>To protect against legal liability</li>
            </ul>
            <p><strong>Service Providers</strong></p>
            <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to
                provide the Service on our behalf, to perform Service-related services or to assist us in analysing how
                our Service is used.</p>
            <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are
                obligated not to disclose or use it for any other purpose.</p>
            <p><strong>Links to Other Sites</strong></p>
            <p>Our Service may also contain links to other sites that are not operated by us such as our social media
                buttons. If you click on a third-party link, you will be directed to that third party’s site. We
                strongly advise you to review the Privacy Policy of every site you visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of
                any third-party sites or services.</p>
            <p><strong>Children’s Privacy</strong></p>
            <p>Our Service does not address anyone under the age of 18 (“Children”).</p>
            <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you
                are a parent or guardian and you are aware that your Children has provided us with Personal Data, please
                contact us. If we become aware that we have collected Personal Data from children without verification
                of parental consent, we take steps to remove that information from our servers.</p>
            <p><strong>Changes to this Privacy Policy</strong></p>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on this page.</p>
            <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
                effective and update the “effective date” at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.</p>
            <p><strong>Your rights</strong></p>
            <p>Data protection law gives rights to individuals in respect of the personal data that organizations
                collect and hold about them. You have the right to be informed about the processing of your personal
                data and also have the right to ask us not to share your personal data. You have the right to choose
                what information we can obtain, as well as who we can disclose such information to. If your personal
                information changes in the future you have the right to modify or correct your account by making changes
                in your account information page or by email at <a
                    href="mailto:info@taajservices.com">info@taajservices.com</a>Please be advised that if you no longer
                wish to use our service and would like to delete your account, contact us via the e-mail above.</p>
            <p><strong>Security</strong></p>
            <p>We will take all the necessary steps to make sure that your Personal Data is treated securely and in
                accordance to the Privacy Policy. Although, no method of transmission over the internet as well as
                electronic storage is 100%. We do implement technical and organization measures to protect your data. We
                take up procedural safeguards that everyone on involved abides by. We limit access to your personal data
                to only employees, agents and representatives who need to know. We also put in place SSL (Secure Socket
                layer) which is a type of encryption technology to protect your important and highly sensitive
                information such as bank account number, date of birth, passwords etc.</p>
            <p><strong>Testimonials</strong></p>
            <p>We present personal testimonials of satisfied customers on our website. With your knowledge and consent
                we may also post your testimonial along with or without your name.</p>
            <p><strong>Contact us</strong></p>
            <p>You can contact us at <a href="mailto:info@taajservices.com">info@taajservices.com</a> if you have any
                question regarding this Privacy Policy of would like to enforce any of these rights.</p>
        </div>
    </div>
</div>